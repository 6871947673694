require('./bootstrap');

import Vue from 'vue'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue';
import { Ziggy } from '../../vendor/tightenco/ziggy/dist/vue';
import { createInertiaApp } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVueIcons)

InertiaProgress.init()

Vue.use(BootstrapVue)

Vue.prototype.$route = route

const pages = require.context('./Pages', true, /\.vue$/i)
const shared = require.context('./Shared', true, /\.vue$/i)

pages
    .keys()
    .map((key) =>
        Vue.component(key.split('/').pop().split('.')[0], pages(key).default),
    )

shared
    .keys()
    .map((key) =>
        Vue.component(key.split('/').pop().split('.')[0], shared(key).default),
    )

createInertiaApp({
    resolve: name => require(`./Pages/${name}`),
    setup({ el, app, props }) {
        new Vue({
            render: h => h(app, props),
        }).$mount(el)
    },
})