<template>
    <div class="container mt-5">
        <div class="form-group row">
            <div class="p-5 m-4 border rounded-0 bg-white col">
                <label v-if="answer == 1" style="color: green;">Yes, Congratulations!! You may be eligible. We will have someone Contact you as soon as possible to walk you through the process.</label>
                <label v-else style="color: red;">Sorry you are not eligible, but we will have someone contact you for other options.</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        answer: Boolean
    },
    data() {
        return {};
    },
    methods: {}
}
</script>

<style scoped>
label {
    padding-right: 15px;
    padding-left: 15px;
    font-weight: bold;
}
</style>
