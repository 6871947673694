<template>
    <div>
        <p>This is Index/test</p>
    </div>
</template>

<script>
export default {
    props: ['fname','lname'],
}
</script>
