<template>
    <div class="container mt-5">
        <div v-if="page == 1" class="row">
            <form @submit.prevent="submitData" class="p-5 m-4 border rounded-0 bg-white col"  style="width: 60%">
                <div class="form-group">
                    <label>LEGAL BUSINESS NAME <span style="color: red;">*</span></label>
                    <div class="col-md-12">
                        <input class="form-control m-b-5" required placeholder="" v-model="form.legal_business_name" />
                    </div>
                </div>
                <div class="form-group">
                    <label>NAME <span style="color: red;">*</span></label>
                    <div class="col-md-12">
                        <input class="form-control m-b-5" required placeholder="" v-model="form.name" />
                    </div>
                </div>

                <div class="form-group">
                    <label>CONTACT # <span style="color: red;">*</span></label>
                    <div class="col-md-12">
                        <input class="form-control m-b-5" required placeholder="" v-model="form.contact" />
                    </div>
                </div>

                <div class="form-group">
                    <label>YEARLY REVENUE <span style="color: red;">*</span></label>
                    <div class="col-md-12">
                        <select v-model="form.scale_option" required class="form-control">
                            <option value="0-250000">0 - $250,000</option>
                            <option value="250000-500000">$250,000 - $500,000</option>
                            <option value="500000+">$500,000+</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-12">
                        <button class="btn btn-primary"><i class="fa fa-check"></i> Next</button>
                    </div>
                </div>
            </form>
        </div>

        <div v-if="page == 2" class="row">
            <form @submit.prevent="submitData2" class="p-5 m-4 border rounded-0 bg-white col">
                <div class="form-group">
                    <label>Has your business been adversely affected by covid? <span style="color: red;">*</span></label>
                    <div class="col-md-12">
                        <b-form-radio-group v-model="form.affected_by_covid">
                            <b-form-radio :value="1">Yes</b-form-radio>
                            <b-form-radio :value="0">No</b-form-radio>
                        </b-form-radio-group>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-12">
                        <button class="btn btn-primary"><i class="fa fa-check"></i> Next</button>
                    </div>
                </div>
            </form>
        </div>

        <div v-if="page == 3" class="row">
            <form @submit.prevent="submitData3" class="p-5 m-4 border rounded-0 bg-white col">
                <div class="form-group" v-if="form.affected_by_covid">
                    <label>Have you had a decrease of 50% or more in Revenue for 2 or more months in the past year? <span style="color: red;">*</span></label>
                    <div class="col-md-12">
                        <b-form-radio-group v-model="form.decrease_of_revenue">
                            <b-form-radio :value="1">Yes</b-form-radio>
                            <b-form-radio :value="0">No</b-form-radio>
                        </b-form-radio-group>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-12">
                        <button class="btn btn-primary"><i class="fa fa-check"></i> Next</button>
                    </div>
                </div>
            </form>
        </div>

        <!--
        <div class="form-group" style="color: red;" v-if="form.affected_by_covid == 0 || form.decrease_of_revenue == 0">
            <label>Sorry you are not eligible, but we will have someone contact you for other options.</label>
        </div>
        -->
    </div>
</template>

<script>
export default {
    props: [],
    data() {
        return {
            page: 1,
            form: {
                legal_business_name: null,
                name: null,
                contact: null,
                scale_option: null,
                affected_by_covid: null,
                decrease_of_revenue: null,
            },
        };
    },
    methods: {
        async submitData() {
            this.page = 2;

        },
        async submitData2() {
            if (this.form.affected_by_covid == 0) {
                this.$inertia.post(this.$route('form.submissions.store'), this.form, {
                    onSuccess: () => {},
                })
            } else if (this.form.affected_by_covid == 1) {
                this.page = 3;
            }
        },
        async submitData3() {
            if (this.form.decrease_of_revenue == 0 || this.form.decrease_of_revenue == 1) {
                this.$inertia.post(this.$route('form.submissions.store'), this.form, {
                    onSuccess: () => {},
                })
            }
        }
    }
}
</script>

<style scoped>
label {
    padding-right: 15px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 16px;
    color: #6c757d;
}
</style>
